import * as React from 'react';

import BtnNotified from '../BtnNotified/BtnNotified';

import './Card.scss';


const Card = ({userType, services}) => {

    const serviceList = (service) =>{
        if(service === 'regular'){
            return(
                <ul className="services">
                    <li>Workout/Meal plans</li>
                    <li>Meal Delivery/In-house Cooking</li>
                    <li>Follow-up/Community</li>
                    <li>...</li>
                </ul> 
            )
        }
        else if(service === 'coach'){
            return(
                <ul className="services">
                    <li>Provide Workout/Meal Plan</li>
                    <li>Book Fitness sessions with clients</li>
                    <li>Follow-up with Students</li>
                    <li>...</li>
                </ul> 
            )
        }
            else 
            return(
                <ul className="services">
                    <li>Provide Recipes for Meal Plan</li>
                    <li>Cook/Food Delivery</li>
                    <li>In-house Cooking</li>
                    <li>...</li>
                </ul>
            )
    }



    return(
        <div className={`card card__${services}`}>
            <h4 className="for">For</h4>
            <h2 className="user-type">{userType}</h2>
            {serviceList(services)}
            <BtnNotified services={services}/>
        </div>
    )
}

export default Card;