import * as React from "react";

import Header from "../components/Header/Header";
import IllustrationGrid from "../components/IllustrationGrid/IllustrationGrid";
import Intro from "../components/Intro/Intro";
import Card from "../components/Card/Card";
import CountDown from "../components/CountDown/CountDown";
import BtnNotified from "../components/BtnNotified/BtnNotified";



import '../scss/index.scss';
import Footer from "../components/Footer/Footer";

const IndexPage = () => {
  return (
    <main>
      <Header />
      <IllustrationGrid />
      <Intro 
        title='A simple solution to the best lifestyle is coming soon'
        paragraph='Do you want to commit to a healthier lifestyle but you find it difficult to balance time and budget wise?
            &nbsp;CompanionShape is the new solution that helps you balance fitness and healthy meals <strong>cost-effectively</strong>.'
        showBtn={true}

        />
      <div className="cards-container">
        <Card userType="Regular Users" services="regular"/>
        <Card userType="Coaches" services="coach"/>
        <Card userType="Chefs" services="chef"/>
      </div>
      <Footer>
        <CountDown />
        <BtnNotified />
      </Footer>
    </main>
  )
}

export default IndexPage
