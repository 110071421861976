import * as React from 'react';

import IllustrationOne from '../../images/illustration-1.png';
import IllustrationTwo from '../../images/illustration-2.png';
import IllustrationThree from '../../images/illustration-3.png';


import './IllustrationGrid.scss';


const IllustrationGrid  = () => {
    return(
        <div className='illustrations-grid'>
            <img className="img-1" src={IllustrationOne} alt="illustration-1"/>
            <img className="img-2" src={IllustrationTwo} alt="illustration-2"/>
            <img className="img-3" src={IllustrationThree} alt="illustration-3"/>
        </div>
    )
}

export default IllustrationGrid;